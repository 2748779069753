export const UserLang = Object.freeze({
    PL: "pl"
});

export const UserRole = Object.freeze({
    ADMIN: "admin"
});
export const UserRoleLabel = Object.freeze({
    [UserRole.ADMIN]: "Administrator"
});
export const UserRoleLabelColor = Object.freeze({
    [UserRole.ADMIN]: "primary"
});

export const UserStatus = Object.freeze({
    CREATED: "created",
    ACTIVE: "active",
    SUSPENDED: "suspended"
});
export const UserStatusLabel = Object.freeze({
    [UserStatus.CREATED]: "Wstępnie utworzone",
    [UserStatus.ACTIVE]: "Aktywne",
    [UserStatus.SUSPENDED]: "Zawieszone"
});
export const UserStatusLabelColor = Object.freeze({
    [UserStatus.CREATED]: "secondary",
    [UserStatus.ACTIVE]: "success",
    [UserStatus.SUSPENDED]: "grey"
});
